import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from 'components/Button';
import { Checkbox } from 'components/Form/Checkbox';
import Loading from 'components/Loading';
import { Modal } from 'components/Modal';
import Paragraph from 'components/Paragraph';
import { SearchableListContainer } from 'components/SearchableListContainer';
import Spacer from 'components/Spacer';
import { Table } from 'components/Table';
import { useGetReserveOrderTickets } from '../../gql/queries/get-reserved-order-tickets';

export const ReservedOrderDetail = ({
  purchaseId,
  selectedSeats,
  setSelectedSeats,
  onMoveSeats,
}) => {
  const { data, loading } = useGetReserveOrderTickets(purchaseId);

  const [isVisible, setIsVisible] = useState(true);

  const columns = [
    {
      label: 'Move',
      key: 'move',
    },
    {
      label: 'Ticket Name',
      key: 'ticket_name',
    },
    {
      label: 'Ticket Serial Number',
      key: 'barcode',
    },
    {
      label: 'Section',
      key: 'section',
    },
    {
      label: 'Row',
      key: 'row',
    },
    {
      label: 'Seat',
      key: 'seat',
    },
    {
      label: 'Ticket Holder',
      key: 'ticket_holder',
    },
  ];

  function renderRows(rowData) {
    const { id, name, barcode, row, section, seat, ticket_holder } = rowData;

    const selectedSeatsIds = selectedSeats
      ? selectedSeats.map((seat) => seat.id)
      : [];
    const is_checked = selectedSeatsIds && selectedSeatsIds?.includes(id);

    return {
      move: (
        <Checkbox
          aria-checked={!!is_checked}
          checked={!!is_checked}
          inversed
          name="move"
          onChange={() => {
            setSelectedSeats((seats) => {
              if (is_checked) return seats.filter((seat) => seat.id !== id);
              else return seats ? seats.concat(rowData) : [rowData];
            });
          }}
          role="checkbox"
          tabIndex={0}
          myStyle={{ cursor: 'pointer ' }}
        />
      ),
      ticket_name: name,
      barcode: barcode,
      section: section,
      row: row,
      seat: seat,
      ticket_holder: ticket_holder.formatted_name,
    };
  }

  return (
    <SearchableListContainer
      noSearch
      title="Order Details"
      customStyle={{ width: '100%', margin: 0, border: 'none' }}
      contentPadding={0}
    >
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginInline: 'auto',
            padding: 20,
          }}
        >
          <Loading size={50} />
        </div>
      ) : !data || data?.getReserveOrderTickets?.length < 1 ? (
        <Paragraph textAlign="center" lineHeight={100} fontSize={20}>
          No Order Details
        </Paragraph>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <Table
            items={data?.getReserveOrderTickets}
            columns={columns}
            renderRows={renderRows}
          />

          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              paddingRight: 20,
            }}
          >
            <Button
              disabled={
                loading || !selectedSeats || selectedSeats?.length === 0
              }
              onClick={onMoveSeats}
            >
              Continue
            </Button>
          </div>
        </div>
      )}

      <MoveSeatInfoModal isVisible={isVisible} setIsVisible={setIsVisible} />
    </SearchableListContainer>
  );
};

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
`;

const NumberText = styled.span`
  font-size: 50px;
  line-height: 40px;
  font-weight: 700;
  color: #fa4616;
  font-family: 'Barlow Condensed';
  margin-right: 50px;
`;

const InfoText = styled.span`
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  color: #00001f;
  font-family: 'Barlow Condensed';
  text-align: start;
  max-width: 400px;
`;

const MoveSeatInfoModal = ({ isVisible, setIsVisible }) => {
  return (
    <Modal
      isVisible={isVisible}
      hideModal={() => setIsVisible(false)}
      title={'Moving Reserved Seats'}
      maxWidth={600}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
          padding: 35,
          justifyContent: 'center',
        }}
      >
        <InfoText style={{maxWidth: '100%'}}>
          The following process will walk you through the process of moving
          reserved seats.
        </InfoText>

        <Spacer size={15} />
        <InfoRow>
          <NumberText>1</NumberText>
          <InfoText> Select all the tickets which need to be moved.</InfoText>
        </InfoRow>

        <Spacer size={15} />
        <InfoRow>
          <NumberText>2</NumberText>
          <InfoText>
            {' '}
            Once you have selected all the tickets, click continue.
          </InfoText>
        </InfoRow>

        <Spacer size={15} />
        <InfoRow>
          <NumberText>3</NumberText>
          <InfoText> On the map, select the new seat locations.</InfoText>
        </InfoRow>
      </div>
    </Modal>
  );
};
