import { Formik } from 'formik';
import qs from 'qs';
import React, { Fragment, useState } from 'react';
import { getCountryCallingCode } from 'react-phone-number-input';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { compose } from 'recompose';
import styled, { useTheme } from 'styled-components';
import { AccountsSelect } from 'components/AccountsDropdown';
import { Button } from 'components/Button';
import Container from 'components/Container';
import ContainerHeader from 'components/ContainerHeader';
import { DrawerPadding } from 'components/Drawer';
import { DatePicker } from 'components/Form/DatePicker';
import { Input } from 'components/Form/Input';
import { PhoneInput } from 'components/Form/PhoneInput';
import { Select } from 'components/Form/Select';
import Spacer from 'components/Spacer';
import Text from 'components/Text';
import {
  ResponsiveCol,
  ResponsiveRow,
  TabContent,
} from 'pages/app/Events/AddEvents/styles';
import { TitleContainer } from 'pages/track/Contact/style';
import CreateUser from '../gql/mutations/create-user';
import UpdateUser from '../gql/mutations/update-user';
import GetUser from '../gql/queries/get-user';
import Capabilities from './Capabilities';

const DataRow = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 28px;
  box-sizing: border-box;
  cursor: pointer;

  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

const formValues = [
  'first_name',
  'middle_name',
  'last_name',
  'suffix',
  'role',
  'birthday',
  'expiration',
  'cellphone',
  'country_code',
  'calling_code',
  'email',
  'address_1',
  'address_2',
  'city',
  'state',
  'zipcode',
  'password',
  'inactive',
  'capabilities',
  'account_ids',
  'scanner_id',
];

export const Tabs = styled.div`
  overflow: hidden;
  background: #fff;
  font-family: Barlow Condensed;
  font-weight: 600;
  height: 4em;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    overflow: visible;
    background: #fff;
    height: 7em;
    padding: 10px;
  }
`;

const Tab = styled.button.attrs({ type: 'button' })`
  border: none;
  color: ${(props) => (props.active ? '#FE5000' : '#000000')};
  outline: none;
  cursor: pointer;
  width: ${(props) => (props.width ? props.width : '18%')};
  position: relative;
  margin-right: 0.1em;
  font-size: 1em;
  font-weight: bold;
  border-bottom: ${(props) => (props.active ? '3px solid #FE5000' : '')};
  background-color: ${(props) => (props.active ? 'white' : 'white')};
  height: ${(props) => (props.active ? '3.5em' : '2.6em; top:.4em')};
  transition: background-color 0.5s ease-in-out;
  font-family: Barlow Condensed;
  :hover {
    background-color: white;
  }

  @media (max-width: 768px) {
    width: ${(props) => (props.width ? props.width : '12%')};
    font-size: 0.8em;
  }
`;

const capabilityKeys = [
  'account_id',
  'events',
  'guests',
  'add_guests',
  'check_in_guests',
  'messages',
  'add_promos',
  'promos',
  'credits',
  'issue_credits',
  'transactions',
  'issue_refunds',
  'registrations',
  'customers',
  'memberships',
  'staff',
  'add_staff',
  'stripe',
  'scan_tickets',
  'check_tickets',
  'create_messages',
  'edit_registrations',
  'memberships',
  'edit_memberships',
  'edit_billing',
  'pos',
];

const UserEdit = ({ createUser, updateUser, data, history }) => {
  const params = useParams();
  const currentUser = params.id;

  const theme = useTheme();

  const successMessage = () =>
    toast.success(currentUser ? 'User Updated' : 'User Created');
  const errorMessage = (response) =>
    toast.error(
      currentUser
        ? response.global
          ? 'Error Updating User'
          : "There were errors with your submission check the form's field for errors."
        : 'Error Creating User'
    );

  const { isTransfer } = qs.parse(window.location.search.substring(1));

  //tab configuration for capabailities under each account
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const [active, setActive] = useState(0);

  const handleClick = (e) => {
    const index = parseInt(e.target.id, 0);
    if (index !== active) {
      setActive(index);
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formValues.reduce((acc, value) => {
        if ('password' === value) {
          return acc;
        }

        if ('account_ids' === value && data.getUser?.accounts) {
          acc[value] = data.getUser.accounts.map((account) => ({
            label: account.business_dba,
            value: account.id,
          }));
        } else if (
          value === 'cellphone' &&
          data.getUser &&
          data.getUser[value]
        ) {
          if (data.getUser[value].includes('+'))
            acc[value] = data.getUser[value];
          else
            acc[value] = `+${
              data.getUser['calling_code'] ? data.getUser['calling_code'] : '1'
            }${data.getUser[value]}`;
        } else {
          acc[value] =
            currentUser && data.getUser && data.getUser[value]
              ? data.getUser[value]
              : '';
        }

        return acc;
      }, {})}
      validate={(values) => {
        const errors = {};
        if (!values.first_name) {
          errors.first_name = 'Required';
        }

        if (!values.last_name) {
          errors.last_name = 'Required';
        }

        if (!values.role) {
          errors.role = 'Required';
        }

        if (!values.cellphone) {
          errors.cellphone = 'Required';
        }

        if (values.role === 'track' || values.role === 'employee') {
          if (!values.account_ids) {
            errors.account_ids = 'Required';
          }
        }

        return errors;
      }}
      onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
        let response;
        setSubmitting(true);

        const data = Object.entries(values).reduce((acc, entry) => {
          acc[entry[0]] = '' === entry[1] ? null : entry[1];
          return acc;
        }, {});

        if (currentUser) {
          data['capabilities'].forEach(
            (capability) => delete capability['__typename']
          );
        }

        data['cellphone'] = data['cellphone'].replace(
          `+${data.calling_code}`,
          ''
        );

        if (currentUser) {
          if (data.role === 'admin' || data.role === 'user') {
            data.account_ids = [];
            data.capabilities = [];
          }
          response = await updateUser({
            ...data,
            user_id: currentUser,
            account_ids:
              data.account_ids && data.account_ids.length
                ? data.account_ids.map((account) => account.value)
                : null,
          });
        } else {
          delete data.inactive;
          data['cellphone'] = data['cellphone']
            ? data['cellphone'].replace(`+${data.calling_code}`, '')
            : '';
          response = await createUser({
            ...data,
            account_ids:
              data.account_ids && data.account_ids.length
                ? data.account_ids.map((account) => account.value)
                : null,
          });
        }

        if (!response || response.errors) {
          errorMessage(response);
          setSubmitting(false);
          return setErrors(response.errors);
        } else {
          successMessage();
          setSubmitting(false);
          resetForm();
          history.push('/admin/users');
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => {
        const addCapability = (account_ids) => {
          let newCapability;
          if (!account_ids) {
            setFieldValue('capabilities', []);
          } else if (values.capabilities?.length > account_ids?.length) {
            newCapability = values.capabilities.reduce((acc, capability) => {
              if (account_ids.find((id) => id === capability.account_id))
                return [...acc, capability];
              else return acc;
            }, []);

            if (active === account_ids?.length)
              setActive(account_ids?.length - 1);
            setFieldValue('capabilities', newCapability);
          } else {
            const account_id = account_ids[account_ids.length - 1];
            newCapability = capabilityKeys.reduce((acc, key) => {
              return key === 'account_id'
                ? { ...acc, account_id }
                : { ...acc, [key]: false };
            }, {});
            setFieldValue('capabilities', [
              ...values.capabilities,
              newCapability,
            ]);
          }
        };

        return (
          <form onSubmit={handleSubmit}>
            <Container>
              <ContainerHeader>
                <TitleContainer>
                  <Text
                    type="heading"
                    as="h1"
                    color={theme.colors.text.header}
                    inlineStyle={{ marginRight: 25, whiteSpace: 'nowrap' }}
                  >
                    User Details
                  </Text>
                </TitleContainer>
                <ButtonWrapper>
                  {isTransfer !== 'true' && (
                    <Button type="submit" disabled={isSubmitting} block>
                      {currentUser ? 'Update User' : 'Create User'}
                    </Button>
                  )}
                </ButtonWrapper>
              </ContainerHeader>
              <DrawerPadding border>
                <ResponsiveRow>
                  <ResponsiveCol>
                    <Input
                      id="first_name"
                      label="First Name"
                      placeholder="First Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.first_name}
                      error={
                        errors.first_name &&
                        touched.middle_name &&
                        errors.first_name
                      }
                    />
                    <Spacer size={18} />
                    <Input
                      id="last_name"
                      label="Last Name"
                      placeholder="Last Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.last_name}
                      error={
                        errors.last_name &&
                        touched.last_name &&
                        errors.last_name
                      }
                    />
                    {process.env.REACT_APP_PLATFORM === 'tickethoss' &&
                    !values.middle_name ? null : (
                      <Fragment>
                        <Spacer size={18} />
                        <Input
                          id="middle_name"
                          name="middle_name"
                          label="Middle Name"
                          placeholder="Middle Name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.middle_name}
                          error={
                            errors.middle_name &&
                            touched.middle_name &&
                            errors.middle_name
                          }
                        />
                      </Fragment>
                    )}
                    {process.env.REACT_APP_PLATFORM === 'tickethoss' &&
                    !values.suffix ? null : (
                      <Fragment>
                        <Spacer size={18} />
                        <Input
                          id="suffix"
                          label="Suffix"
                          placeholder="Suffix"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.suffix}
                          error={
                            errors.suffix && touched.suffix && errors.suffix
                          }
                        />
                      </Fragment>
                    )}

                    <Spacer size={13} />

                    <Select
                      id="role"
                      label="Role"
                      options={[
                        {
                          label: 'Select User Role...',
                          value: '',
                        },
                        {
                          label:
                            process.env.REACT_APP_PLATFORM === 'pitpay'
                              ? 'Pit Pay'
                              : 'Tickethoss',
                          value: 'admin',
                        },
                        {
                          label: 'Account Owner',
                          value: 'track',
                        },
                        {
                          label: 'Staff',
                          value: 'employee',
                        },
                        {
                          label: 'App User',
                          value: 'user',
                        },
                      ]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.role}
                      error={errors.role && touched.role && errors.role}
                    />
                    <Spacer size={13} />

                    {currentUser && (
                      <Select
                        id="inactive"
                        name="inactive"
                        label="User status"
                        placeholder="User status"
                        options={[
                          {
                            label: 'Active',
                            value: 'active',
                          },
                          {
                            label: 'Inactive',
                            value: 'inactive',
                          },
                        ]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.inactive}
                        error={
                          errors.inactive && touched.inactive && errors.inactive
                        }
                      />
                    )}

                    <Spacer size={5} />
                  </ResponsiveCol>
                  <ResponsiveCol>
                    <Input
                      id="email"
                      label="Email"
                      placeholder="name@email.com"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      error={errors.email && touched.email && errors.email}
                    />
                    {process.env.REACT_APP_PLATFORM === 'tickethoss' &&
                    !values.birthday ? null : (
                      <Fragment>
                        <Spacer size={18} />
                        <DatePicker
                          id="birthday"
                          label="DoB"
                          type="date"
                          name="birthday"
                          onChange={setFieldValue}
                          value={values.birthday}
                          error={
                            errors.birthday &&
                            touched.birthday &&
                            errors.birthday
                          }
                        />
                      </Fragment>
                    )}

                    {process.env.REACT_APP_PLATFORM === 'tickethoss' &&
                    !values.expiration ? null : (
                      <Fragment>
                        <Spacer size={18} />
                        <DatePicker
                          id="expiration"
                          label="Expiration"
                          type="date"
                          name="expiration"
                          onChange={setFieldValue}
                          value={values.expiration}
                          error={
                            errors.expiration &&
                            touched.expiration &&
                            errors.expiration
                          }
                        />
                      </Fragment>
                    )}

                    <Spacer size={18} />
                    <PhoneInput
                      id="cellphone"
                      placeholder="___ ___ ____"
                      size={25}
                      label="Phone Number"
                      onBlur={handleBlur}
                      onChange={(value) => {
                        setFieldValue('cellphone', value ?? '');
                      }}
                      onCountryChange={(e) => {
                        if (e) {
                          setFieldValue('country_code', e ?? '');
                          setFieldValue(
                            'calling_code',
                            getCountryCallingCode(e) ?? ''
                          );
                        }
                      }}
                      value={values.cellphone}
                      error={errors.cellphone}
                    />
                    <Spacer size={18} />
                    <Input
                      id="scanner_id"
                      label="Login Code"
                      placeholder="Code"
                      onChange={handleChange}
                      value={values.scanner_id}
                      error={
                        errors.scanner_id &&
                        touched.scanner_id &&
                        errors.scanner_id
                      }
                    />

                    <Spacer size={18} />
                  </ResponsiveCol>
                  <ResponsiveCol>
                    {process.env.REACT_APP_PLATFORM === 'tickethoss' &&
                    !values.address_1 ? null : (
                      <Input
                        id="address_1"
                        label="Address Line 1"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.address_1}
                        error={
                          errors.address_1 &&
                          touched.address_1 &&
                          errors.address_1
                        }
                      />
                    )}

                    {process.env.REACT_APP_PLATFORM === 'tickethoss' &&
                    !values.address_2 ? null : (
                      <Fragment>
                        <Spacer size={18} />
                        <Input
                          id="address_2"
                          label="Address Line 2"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.address_2}
                          error={
                            errors.address_2 &&
                            touched.address_2 &&
                            errors.address_2
                          }
                        />
                      </Fragment>
                    )}
                    {process.env.REACT_APP_PLATFORM === 'tickethoss' &&
                    !values.city ? null : (
                      <Fragment>
                        <Spacer size={18} />
                        <Input
                          id="city"
                          label="City"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.city}
                          error={errors.city && touched.city && errors.city}
                        />
                      </Fragment>
                    )}

                    {process.env.REACT_APP_PLATFORM === 'tickethoss' &&
                    !values.state ? null : (
                      <Fragment>
                        <Spacer size={18} />
                        <Input
                          id="state"
                          label="State / Province"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.state}
                          error={errors.state && touched.state && errors.state}
                        />
                      </Fragment>
                    )}

                    {process.env.REACT_APP_PLATFORM === 'tickethoss' &&
                    !values.zipcode ? null : (
                      <Fragment>
                        <Spacer size={18} />
                        <Input
                          id="zipcode"
                          label="Zipcode"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.zipcode}
                          error={
                            errors.zipcode && touched.zipcode && errors.zipcode
                          }
                        />
                      </Fragment>
                    )}
                    <Spacer size={18} />
                  </ResponsiveCol>
                </ResponsiveRow>

                {(values.role === 'track' || values.role === 'employee') && (
                  <ResponsiveRow>
                    <ResponsiveCol>
                      <AccountsSelect
                        isMulti
                        name="account_ids"
                        addCapability={addCapability}
                        label="Track or Series"
                        isAdmin
                      />
                    </ResponsiveCol>
                    <ResponsiveCol></ResponsiveCol>
                  </ResponsiveRow>
                )}
              </DrawerPadding>
              {(values.role === 'track' || values.role === 'employee') && (
                <DrawerPadding>
                  {values.capabilities &&
                    values.account_ids &&
                    values.account_ids.length > 0 && (
                      <>
                        <Text
                          type="heading"
                          as="h1"
                          color={theme.colors.text.header}
                          inlineStyle={{
                            margin: '25 5',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          User Permissions
                        </Text>
                        <Spacer size={10} />
                        <TabContent>
                          <div
                            style={{
                              backgroundColor: '#F4F4F4',
                              margin: '10 0',
                            }}
                          >
                            <Tabs>
                              {values?.capabilities.map((cap, index) => {
                                let tab;
                                values.account_ids.forEach((account) => {
                                  if (account.value === cap.account_id)
                                    tab = account;
                                });
                                return (
                                  <Tab
                                    onClick={(e) => handleClick(e)}
                                    active={active === index}
                                    width={isMobile ? '100%' : null}
                                    id={index}
                                    key={index}
                                    style={{
                                      fontFamily: 'Roboto',
                                      fontSize: '16px',
                                      fontWeight: '500',
                                    }}
                                  >
                                    {tab.label}
                                  </Tab>
                                );
                              })}
                            </Tabs>
                          </div>
                        </TabContent>

                        <Spacer size={18} />
                        {values.capabilities.map(
                          (capability, index) =>
                            active === index && (
                              <Capabilities
                                key={index}
                                mainValue={values}
                                values={capability}
                                errors={errors}
                                touched={touched}
                                handleBlur={handleBlur}
                                setFieldValue={setFieldValue}
                                index={index}
                                handleChange={({ target }) => {
                                  const newCapability = values.capabilities.map(
                                    (capability, capIndex) =>
                                      capIndex === index
                                        ? {
                                            ...capability,
                                            [target.name]: target.value,
                                          }
                                        : capability
                                  );
                                  setFieldValue('capabilities', newCapability);
                                }}
                                handleMultipleChange={(target) => {
                                  const newCapability = values.capabilities.map(
                                    (capability, capIndex) =>
                                      capIndex === index
                                        ? {
                                            ...capability,
                                            ...target,
                                          }
                                        : capability
                                  );
                                  setFieldValue('capabilities', newCapability);
                                }}
                                capabilityKeys={capabilityKeys}
                              />
                            )
                        )}
                      </>
                    )}
                </DrawerPadding>
              )}

              {data.getUser && data.getUser?.parents?.length > 0 && (
                <DrawerPadding>
                  <Text
                    type="heading"
                    as="h1"
                    color={theme.colors.text.header}
                    inlineStyle={{ margin: '25 5', whiteSpace: 'nowrap' }}
                  >
                    Minor Info
                  </Text>

                  {data.getUser.parents.length
                    ? data.getUser.parents.map((parent, index) => (
                        <>
                          <Spacer size={18} />

                          <DataRow>
                            <Text
                              inlineStyle={{ fontSize: 18, fontWeight: 600 }}
                            >
                              {`Parent #${index + 1}`}
                            </Text>
                          </DataRow>
                          <Spacer size={10} />
                          <DataRow>
                            <Text
                              inlineStyle={{
                                fontSize: 16,
                                fontWeight: 600,
                                marginRight: 10,
                              }}
                            >
                              Name:
                            </Text>
                            <Text
                              inlineStyle={{
                                fontSize: 16,
                                fontWeight: 400,
                                color: '#707273',
                              }}
                            >
                              {` ${parent.first_name} ${parent.last_name}`}
                            </Text>
                          </DataRow>
                          <Spacer size={10} />
                          <DataRow>
                            <Text
                              inlineStyle={{
                                fontSize: 16,
                                fontWeight: 600,
                                marginRight: 10,
                              }}
                            >
                              Phone:
                            </Text>
                            <Text
                              inlineStyle={{
                                fontSize: 16,
                                fontWeight: 400,
                                color: '#707273',
                              }}
                            >
                              {parent.cellphone}
                            </Text>
                          </DataRow>
                          <Spacer size={18} />
                        </>
                      ))
                    : null}
                </DrawerPadding>
              )}
            </Container>
          </form>
        );
      }}
    </Formik>
  );
};

export default compose(CreateUser, UpdateUser, GetUser)(UserEdit);
