import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';

export const GET_TRANSACTION = gql`
  query GetTrackTransaction($purchase_id: Int!) {
    getTrackTransaction(purchase_id: $purchase_id) {
      id
      fee
      subtotal
      total
      refund
      discount
      credit
      transfer_id
      charge_id
      card {
        last4
      }
      purchase_date
      status
      user {
        id
        name
        first_name
        last_name
        formatted_name
      }
      users {
        promo
        user {
          id
          name
          first_name
          last_name
        }
        tickets {
          id
          name
          price
          refunded
          type
          barcode
          section
          row
          seat
          date
        }
      }
      international_fee
      order_source
      payment_method
      register
      credits_issued
      refunded_amount
      event {
        name
      }
      track {
        name
      }
    }
  }
`;

export function useGetTrackTransaction(id, fetch) {
  return useQuery(GET_TRANSACTION, {
    variables: {
      purchase_id: Number(id),
    },
    skip: !fetch || !id,
  });
}
