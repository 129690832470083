import React, { Fragment, useState } from 'react';
import { useTheme } from 'styled-components';
import { Checkbox } from 'components/Form/Checkbox';
import { Radio } from 'components/Form/Radio';
import Spacer from 'components/Spacer';
import Text from 'components/Text';
import {
  ResponsiveCol,
  ResponsiveRow,
} from 'pages/app/Events/AddEvents/styles';

function Capabilities({
  values,
  handleChange,
  handleMultipleChange,
  handleBlur,
  errors,
  touched,
  mainValue,
  setFieldValue,
  capabilityKeys,
  isSelfEdit,
  isView,
}) {
  const theme = useTheme();

  const ticketName =
    process.env.REACT_APP_PLATFORM === 'tickethoss' ? 'Tikcets' : 'Passes';

  let fullAccess = true;
  let initialAccess;
  capabilityKeys.forEach((key) => {
    if (key !== 'account_id') {
      if (!values[key]) fullAccess = false;
    }
  });

  if (fullAccess) initialAccess = 'full';
  else {
    initialAccess = 'limited';
  }

  const [access, setAccess] = useState(initialAccess);
  const [oldAccess, setOldAccess] = useState();
  const changeAccess = (access) => {
    const newCapability = capabilityKeys.reduce((acc, key) => {
      return key === 'account_id'
        ? { ...acc, account_id: values.account_id }
        : { ...acc, [key]: access };
    }, {});

    const newCapabilities = mainValue.capabilities.reduce((acc, capability) => {
      if (capability.account_id === values.account_id) {
        if (access) {
          setOldAccess(capability);
          return [...acc, newCapability];
        } else {
          return [...acc, oldAccess ? oldAccess : newCapability];
        }
      } else return [...acc, capability];
    }, []);
    setFieldValue('capabilities', newCapabilities);
  };

  return (
    <Fragment>
      {isSelfEdit || isView ? null : (
        <Radio
          options={[
            {
              label: 'Grant Staff Member Full Access',
              value: 'full',
            },
            {
              label: 'Grant Staff Member Limited Access',
              value: 'limited',
            },
          ]}
          onChange={(val) => {
            if (val.target.value === 'full') {
              changeAccess(true);
            } else {
              changeAccess(false);
            }
            setAccess(val.target.value);
          }}
          value={access}
        />
      )}
      {(access || isView) && (
        <Fragment>
          <ResponsiveRow>
            <ResponsiveCol>
              <Text
                type="heading"
                as="h1"
                color={theme.colors.text.header}
                inlineStyle={{
                  whiteSpace: 'nowrap',
                  fontSize: 20,
                }}
              >
                Events
              </Text>

              <Spacer size={18} />
              <Checkbox
                name="all"
                pointerEvents={isSelfEdit ? 'none' : ''}
                checked={
                  !!values.events &&
                  !!values.scan_tickets &&
                  !!values.check_tickets
                }
                onChange={async (event) => {
                  const value = event.target?.checked;
                  handleMultipleChange({
                    events: value,
                    scan_tickets: value,
                    check_tickets: value,
                  });
                }}
                onBlur={handleBlur}
                rightText="All Access"
              />

              <Spacer size={10} />
              <Checkbox
                name="events"
                pointerEvents={isSelfEdit ? 'none' : ''}
                checked={!!values.events}
                onChange={(event) => {
                  const value = event.target?.checked;
                  handleChange({
                    target: {
                      name: 'events',
                      value,
                    },
                  });
                }}
                onBlur={handleBlur}
                error={errors?.events && touched?.events && errors?.events}
                rightText="View Events"
              />

              <Spacer size={10} />
              <Checkbox
                name="scan_tickets"
                pointerEvents={isSelfEdit ? 'none' : ''}
                checked={!!values.scan_tickets}
                onChange={(event) => {
                  const value = event.target?.checked;
                  handleChange({
                    target: {
                      name: 'scan_tickets',
                      value,
                    },
                  });
                }}
                onBlur={handleBlur}
                error={
                  errors?.scan_tickets &&
                  touched.scan_tickets &&
                  errors?.scan_tickets
                }
                rightText={`Scan ${ticketName}`}
              />

              <Spacer size={10} />
              <Checkbox
                name="check_tickets"
                pointerEvents={isSelfEdit ? 'none' : ''}
                checked={!!values.check_tickets}
                onChange={(event) => {
                  const value = event.target?.checked;
                  handleChange({
                    target: {
                      name: 'check_tickets',
                      value,
                    },
                  });
                }}
                onBlur={handleBlur}
                error={
                  errors?.check_tickets &&
                  touched.check_tickets &&
                  errors?.check_tickets
                }
                rightText={`Manually Check-in ${ticketName}`}
              />
              <Spacer size={18} />
            </ResponsiveCol>
            <ResponsiveCol>
              <Text
                type="heading"
                as="h1"
                color={theme.colors.text.header}
                inlineStyle={{
                  whiteSpace: 'nowrap',
                  fontSize: 20,
                }}
              >
                Promo Codes
              </Text>

              <Spacer size={18} />
              <Checkbox
                name="all2"
                pointerEvents={isSelfEdit ? 'none' : ''}
                checked={!!values.promos && !!values.add_promos}
                onChange={(event) => {
                  const value = event.target?.checked;
                  handleMultipleChange({
                    promos: value,
                    add_promos: value,
                  });
                }}
                onBlur={handleBlur}
                rightText="All Access"
              />

              <Spacer size={18} />
              <Checkbox
                name="promos"
                pointerEvents={isSelfEdit ? 'none' : ''}
                checked={!!values.promos}
                onChange={(event) => {
                  const value = event.target?.checked;
                  handleChange({
                    target: {
                      name: 'promos',
                      value,
                    },
                  });
                }}
                onBlur={handleBlur}
                error={errors?.promos && touched.promos && errors?.promos}
                rightText="View Promo Code"
              />
              <Spacer size={18} />
              <Checkbox
                name="add_promos"
                pointerEvents={isSelfEdit ? 'none' : ''}
                checked={!!values.add_promos}
                onChange={(event) => {
                  const value = event.target?.checked;
                  handleChange({
                    target: {
                      name: 'add_promos',
                      value,
                    },
                  });
                }}
                onBlur={handleBlur}
                error={
                  errors?.add_promos && touched.add_promos && errors?.add_promos
                }
                rightText="Issue Promo Codes"
              />
            </ResponsiveCol>
            <ResponsiveCol>
              <Text
                type="heading"
                as="h1"
                color={theme.colors.text.header}
                inlineStyle={{
                  whiteSpace: 'nowrap',
                  fontSize: 20,
                }}
              >
                Staff
              </Text>

              <Spacer size={18} />
              <Checkbox
                name="all3"
                pointerEvents={isSelfEdit ? 'none' : ''}
                checked={!!values.staff && !!values.add_staff}
                onChange={(event) => {
                  const value = event.target?.checked;
                  handleMultipleChange({
                    staff: value,
                    add_staff: value,
                  });
                }}
                onBlur={handleBlur}
                rightText="All Access"
              />

              <Spacer size={18} />
              <Checkbox
                name="staff"
                pointerEvents={isSelfEdit ? 'none' : ''}
                checked={!!values.staff}
                onChange={(event) => {
                  const value = event.target?.checked;
                  handleChange({
                    target: {
                      name: 'staff',
                      value,
                    },
                  });
                }}
                onBlur={handleBlur}
                error={errors?.staff && touched.staff && errors?.staff}
                rightText="View Staff"
              />
              <Spacer size={18} />
              <Checkbox
                name="add_staff"
                pointerEvents={isSelfEdit ? 'none' : ''}
                checked={!!values.add_staff}
                onChange={(event) => {
                  const value = event.target?.checked;
                  handleChange({
                    target: {
                      name: 'add_staff',
                      value,
                    },
                  });
                }}
                onBlur={handleBlur}
                error={
                  errors?.add_staff && touched.add_staff && errors?.add_staff
                }
                rightText="Add Staff"
              />
              <Spacer size={18} />
            </ResponsiveCol>
          </ResponsiveRow>
          <ResponsiveRow>
            <ResponsiveCol>
              <Text
                type="heading"
                as="h1"
                color={theme.colors.text.header}
                inlineStyle={{
                  whiteSpace: 'nowrap',
                  fontSize: 20,
                }}
              >
                Guest lists
              </Text>

              <Spacer size={18} />
              <Checkbox
                name="all4"
                pointerEvents={isSelfEdit ? 'none' : ''}
                checked={
                  !!values.guests &&
                  !!values.add_guests &&
                  !!values.check_in_guests
                }
                onChange={(event) => {
                  const value = event.target?.checked;
                  handleMultipleChange({
                    guests: value,
                    add_guests: value,
                    check_in_guests: value,
                  });
                }}
                onBlur={handleBlur}
                rightText="All Access"
              />

              <Spacer size={18} />
              <Checkbox
                name="guests"
                pointerEvents={isSelfEdit ? 'none' : ''}
                checked={!!values.guests}
                onChange={(event) => {
                  const value = event.target?.checked;
                  handleChange({
                    target: {
                      name: 'guests',
                      value,
                    },
                  });
                }}
                onBlur={handleBlur}
                error={errors?.guests && touched.guests && errors?.guests}
                rightText="View Guest Lists"
              />

              <Spacer size={18} />
              <Checkbox
                name="add_guests"
                pointerEvents={isSelfEdit ? 'none' : ''}
                checked={!!values.add_guests}
                onChange={(event) => {
                  const value = event.target?.checked;
                  handleChange({
                    target: {
                      name: 'add_guests',
                      value,
                    },
                  });
                }}
                onBlur={handleBlur}
                error={
                  errors?.add_guests && touched.add_guests && errors?.add_guests
                }
                rightText="Create Guest List"
              />
              <Spacer size={18} />
              <Checkbox
                name="check_in_guests"
                pointerEvents={isSelfEdit ? 'none' : ''}
                checked={!!values.check_in_guests}
                onChange={(event) => {
                  const value = event.target?.checked;
                  handleChange({
                    target: {
                      name: 'check_in_guests',
                      value,
                    },
                  });
                }}
                onBlur={handleBlur}
                error={
                  errors?.check_in_guests &&
                  touched.check_in_guests &&
                  errors?.check_in_guests
                }
                rightText="Check In Guests"
              />

              <Spacer size={18} />
            </ResponsiveCol>
            <ResponsiveCol>
              <Text
                type="heading"
                as="h1"
                color={theme.colors.text.header}
                inlineStyle={{
                  whiteSpace: 'nowrap',
                  fontSize: 20,
                }}
              >
                Credits
              </Text>

              <Spacer size={18} />
              <Checkbox
                name="all4"
                pointerEvents={isSelfEdit ? 'none' : ''}
                checked={!!values.credits && !!values.issue_credits}
                onChange={(event) => {
                  const value = event.target?.checked;
                  handleMultipleChange({
                    credits: value,
                    issue_credits: value,
                  });
                }}
                onBlur={handleBlur}
                rightText="All Access"
              />

              <Spacer size={18} />
              <Checkbox
                name="credits"
                pointerEvents={isSelfEdit ? 'none' : ''}
                checked={!!values.credits}
                onChange={(event) => {
                  const value = event.target?.checked;
                  handleChange({
                    target: {
                      name: 'credits',
                      value,
                    },
                  });
                }}
                onBlur={handleBlur}
                error={errors?.credits && touched.credits && errors?.credits}
                rightText="View Customer Credits"
              />
              <Spacer size={18} />
              <Checkbox
                name="issue_credits"
                pointerEvents={isSelfEdit ? 'none' : ''}
                checked={!!values.issue_credits}
                onChange={(event) => {
                  const value = event.target?.checked;
                  handleChange({
                    target: {
                      name: 'issue_credits',
                      value,
                    },
                  });
                }}
                onBlur={handleBlur}
                error={
                  errors?.issue_credits &&
                  touched.issue_credits &&
                  errors?.issue_credits
                }
                rightText="Issue Credits"
              />
            </ResponsiveCol>

            <ResponsiveCol>
              <Text
                type="heading"
                as="h1"
                color={theme.colors.text.header}
                inlineStyle={{
                  whiteSpace: 'nowrap',
                  fontSize: 20,
                }}
              >
                Customer Transactions
              </Text>

              <Spacer size={18} />
              <Checkbox
                name="all8"
                pointerEvents={isSelfEdit ? 'none' : ''}
                checked={!!values.transactions && !!values.issue_refunds}
                onChange={(event) => {
                  const value = event.target?.checked;
                  handleMultipleChange({
                    transactions: value,
                    issue_refunds: value,
                  });
                }}
                onBlur={handleBlur}
                rightText="All Access"
              />

              <Spacer size={18} />
              <Checkbox
                name="transactions"
                pointerEvents={isSelfEdit ? 'none' : ''}
                checked={!!values.transactions}
                onChange={(event) => {
                  const value = event.target?.checked;
                  handleChange({
                    target: {
                      name: 'transactions',
                      value,
                    },
                  });
                }}
                onBlur={handleBlur}
                error={
                  errors?.transactions &&
                  touched.transactions &&
                  errors?.transactions
                }
                rightText="View Transactions"
              />
              <Spacer size={18} />
              <Checkbox
                name="issue_refunds"
                pointerEvents={isSelfEdit ? 'none' : ''}
                checked={!!values.issue_refunds}
                onChange={(event) => {
                  const value = event.target?.checked;
                  handleChange({
                    target: {
                      name: 'issue_refunds',
                      value,
                    },
                  });
                }}
                onBlur={handleBlur}
                error={
                  errors?.issue_refunds &&
                  touched.issue_refunds &&
                  errors?.issue_refunds
                }
                rightText="Issue Refunds"
              />

              <Spacer size={18} />
            </ResponsiveCol>
          </ResponsiveRow>
          <ResponsiveRow>
            <ResponsiveCol>
              <Text
                type="heading"
                as="h1"
                color={theme.colors.text.header}
                inlineStyle={{
                  whiteSpace: 'nowrap',
                  fontSize: 20,
                }}
              >
                Message Center
              </Text>

              <Spacer size={18} />
              <Checkbox
                name="all7"
                pointerEvents={isSelfEdit ? 'none' : ''}
                checked={!!values.messages && !!values.create_messages}
                onChange={(event) => {
                  const value = event.target?.checked;
                  handleMultipleChange({
                    messages: value,
                    create_messages: value,
                  });
                }}
                onBlur={handleBlur}
                rightText="All Access"
              />

              <Spacer size={18} />
              <Checkbox
                name="messages"
                pointerEvents={isSelfEdit ? 'none' : ''}
                checked={!!values.messages}
                onChange={(event) => {
                  const value = event.target?.checked;
                  handleChange({
                    target: {
                      name: 'messages',
                      value,
                    },
                  });
                }}
                onBlur={handleBlur}
                error={errors?.messages && touched.messages && errors?.messages}
                rightText="View Messages"
              />

              <Spacer size={18} />
              <Checkbox
                name="create_messages"
                pointerEvents={isSelfEdit ? 'none' : ''}
                checked={!!values.create_messages}
                onChange={(event) => {
                  const value = event.target?.checked;
                  handleChange({
                    target: {
                      name: 'create_messages',
                      value,
                    },
                  });
                }}
                onBlur={handleBlur}
                error={
                  errors?.create_messages &&
                  touched.create_messages &&
                  errors?.create_messages
                }
                rightText="Send Messages"
              />

              <Spacer size={18} />
            </ResponsiveCol>

            <ResponsiveCol>
              <Text
                type="heading"
                as="h1"
                color={theme.colors.text.header}
                inlineStyle={{
                  whiteSpace: 'nowrap',
                  fontSize: 20,
                }}
              >
                Misc
              </Text>

              <Spacer size={18} />
              <Checkbox
                name="all9"
                pointerEvents={isSelfEdit ? 'none' : ''}
                checked={
                  !!values.stripe &&
                  !!values.customers &&
                  !!values.edit_billing &&
                  !!values.pos
                }
                onChange={(event) => {
                  const value = event.target?.checked;
                  handleMultipleChange({
                    stripe: value,
                    customers: value,
                    edit_billing: value,
                    pos: value,
                  });
                }}
                onBlur={handleBlur}
                rightText="All Access"
              />

              <Spacer size={18} />
              <Checkbox
                name="stripe"
                pointerEvents={isSelfEdit ? 'none' : ''}
                checked={!!values.stripe}
                onChange={(event) => {
                  const value = event.target?.checked;
                  handleChange({
                    target: {
                      name: 'stripe',
                      value,
                    },
                  });
                }}
                onBlur={handleBlur}
                error={errors?.stripe && touched.stripe && errors?.stripe}
                rightText="Stripe Access"
              />

              <Spacer size={18} />
              <Checkbox
                name="customers"
                pointerEvents={isSelfEdit ? 'none' : ''}
                checked={!!values.customers}
                onChange={(event) => {
                  const value = event.target?.checked;
                  handleChange({
                    target: {
                      name: 'customers',
                      value,
                    },
                  });
                }}
                onBlur={handleBlur}
                error={
                  errors?.customers && touched.customers && errors?.customers
                }
                rightText="View Customers"
              />

              <Spacer size={18} />
              <Checkbox
                name="edit_billing"
                pointerEvents={isSelfEdit ? 'none' : ''}
                checked={!!values.edit_billing}
                onChange={(event) => {
                  const value = event.target?.checked;
                  handleChange({
                    target: {
                      name: 'edit_billing',
                      value,
                    },
                  });
                }}
                onBlur={handleBlur}
                error={
                  errors?.edit_billing &&
                  touched.edit_billing &&
                  errors?.edit_billing
                }
                rightText="Edit Billing"
              />

              <Spacer size={18} />
              <Checkbox
                name="pos"
                pointerEvents={isSelfEdit ? 'none' : ''}
                checked={!!values.pos}
                onChange={(event) => {
                  const value = event.target?.checked;
                  handleChange({
                    target: {
                      name: 'pos',
                      value,
                    },
                  });
                }}
                onBlur={handleBlur}
                error={errors?.pos && touched.pos && errors?.pos}
                rightText="Use Pos"
              />
            </ResponsiveCol>

            <ResponsiveCol>
              {process.env.REACT_APP_PLATFORM !== 'tickethoss' && (
                <Fragment>
                  <Text
                    type="heading"
                    as="h1"
                    color={theme.colors.text.header}
                    inlineStyle={{
                      whiteSpace: 'nowrap',
                      fontSize: 20,
                    }}
                  >
                    Customer Registrations
                  </Text>

                  <Spacer size={18} />
                  <Checkbox
                    name="all6"
                    pointerEvents={isSelfEdit ? 'none' : ''}
                    checked={
                      !!values.registrations && !!values.edit_registrations
                    }
                    onChange={(event) => {
                      const value = event.target?.checked;
                      handleMultipleChange({
                        registrations: value,
                        edit_registrations: value,
                      });
                    }}
                    onBlur={handleBlur}
                    rightText="All Access"
                  />

                  <Spacer size={18} />
                  <Checkbox
                    name="registrations"
                    pointerEvents={isSelfEdit ? 'none' : ''}
                    checked={!!values.registrations}
                    onChange={(event) => {
                      const value = event.target?.checked;
                      handleChange({
                        target: {
                          name: 'registrations',
                          value,
                        },
                      });
                    }}
                    onBlur={handleBlur}
                    error={
                      errors?.registrations &&
                      touched.registrations &&
                      errors?.registrations
                    }
                    rightText="View Registrations"
                  />
                  <Spacer size={18} />
                  <Checkbox
                    name="edit_registrations"
                    pointerEvents={isSelfEdit ? 'none' : ''}
                    checked={!!values.edit_registrations}
                    onChange={(event) => {
                      const value = event.target?.checked;
                      handleChange({
                        target: {
                          name: 'edit_registrations',
                          value,
                        },
                      });
                    }}
                    onBlur={handleBlur}
                    error={
                      errors?.edit_registrations &&
                      touched.edit_registrations &&
                      errors?.edit_registrations
                    }
                    rightText="Edit Registrations"
                  />
                </Fragment>
              )}
            </ResponsiveCol>
          </ResponsiveRow>
          <ResponsiveRow>
            {process.env.REACT_APP_PLATFORM !== 'tickethoss' && (
              <ResponsiveCol>
                <Fragment>
                  <Text
                    type="heading"
                    as="h1"
                    color={theme.colors.text.header}
                    inlineStyle={{
                      whiteSpace: 'nowrap',
                      fontSize: 20,
                    }}
                  >
                    Memberships
                  </Text>

                  <Spacer size={18} />
                  <Checkbox
                    name="all6"
                    pointerEvents={isSelfEdit ? 'none' : ''}
                    checked={!!values.memberships && !!values.edit_memberships}
                    onChange={(event) => {
                      const value = event.target?.checked;
                      handleMultipleChange({
                        memberships: value,
                        edit_memberships: value,
                      });
                    }}
                    onBlur={handleBlur}
                    rightText="All Access"
                  />

                  <Spacer size={18} />
                  {process.env.REACT_APP_PLATFORM === 'tickethoss' ? null : (
                    <Checkbox
                      name="memberships"
                      pointerEvents={isSelfEdit ? 'none' : ''}
                      checked={!!values.memberships}
                      onChange={(event) => {
                        const value = event.target?.checked;
                        handleChange({
                          target: {
                            name: 'memberships',
                            value,
                          },
                        });
                      }}
                      onBlur={handleBlur}
                      error={
                        errors?.memberships &&
                        touched.memberships &&
                        errors?.memberships
                      }
                      rightText="View Memberships"
                    />
                  )}

                  <Spacer size={18} />
                  <Checkbox
                    name="edit_memberships"
                    pointerEvents={isSelfEdit ? 'none' : ''}
                    checked={!!values.edit_memberships}
                    onChange={(event) => {
                      const value = event.target?.checked;
                      handleChange({
                        target: {
                          name: 'edit_memberships',
                          value,
                        },
                      });
                    }}
                    onBlur={handleBlur}
                    error={
                      errors?.edit_memberships &&
                      touched.edit_memberships &&
                      errors?.edit_memberships
                    }
                    rightText="Edit Memberships"
                  />
                </Fragment>
              </ResponsiveCol>
            )}
          </ResponsiveRow>
        </Fragment>
      )}
    </Fragment>
  );
}

export default Capabilities;
