import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import { Button } from 'components/Button';
import Icon from 'components/Icon';
import Loading from 'components/Loading';
import { Modal } from 'components/Modal';
import Spacer from 'components/Spacer';
import 'react-toastify/dist/ReactToastify.css';
import { printTickets } from 'pages/app/Transactions/TransactionDetail/Components/PrintTickets';
import { useCart } from 'shared/CartContext';
import { useCancelPOS } from './gql/queries/useCancelPOS';
import { useCheckoutPos } from './gql/queries/useCheckoutPOS';
import { usePOSRequest } from './gql/queries/usePOSRequest';
import { usePaymentSubscription } from './gql/subscriptions/payment-subscription';
import MobileDeliveryModal from './MobileDeliveryModal';
import { CheckoutForm } from './StripeModal';

export const successMessage = () => {
  toast.success('Payment Successful', {
    position: 'top-left',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    closeButton: false,
    className: 'custom-toast',
    bodyClassName: 'custom-toast-body',
  });
};

export const errorMessage = () => {
  toast.error('Payment Failed', {
    position: 'top-left',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    closeButton: false,
    className: 'custom-toast',
    bodyClassName: 'custom-toast-body',
  });
};

const STATUS_CONNECTED = 'Connected';

export const PaymentOption = ({
  isVisible,
  setIsVisible,
  manualCardPayment,
  accountIdPayment,
}) => {
  const theme = useTheme();
  const history = useHistory();

  const {
    tickets,
    setPurchaseId,
    connectionStatus,
    setConnectionStatus,
    printer,
  } = useCart();

  const IPAddress = localStorage.getItem('readerIp');
  const ePosDevice = useRef();

  const { id: eventId } = useParams();

  const [loading, setLoading] = useState(true);

  const [paymentError, setPaymentError] = useState(false);
  const { amount, accountId } = useCart();
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const [isCheckoutVisible, setIsCheckoutVisible] = useState(false);

  const [paymentIntent, setPaymentIntent] = useState(null);

  const checkoutPos = useCheckoutPos();

  // Get the paymentIntent from the url
  const urlParams = new URLSearchParams(window.location.search);
  const paymentIntentManual = urlParams.get('payment_intent');
  const redirectSuccess = urlParams.get('redirect_status') === 'succeeded';

  // Get the selected reader from the cookies
  const readerCookie = document.cookie
    .split(';')
    .find((cookie) => cookie.includes(`${accountId}-reader`))
    ?.replace(`${accountId}-reader=`, '')
    .trim();

  const connectPrinter = async () => {
    setConnectionStatus('Connecting ...');

    const ePosDev = new window.epson.ePOSDevice();
    ePosDevice.current = ePosDev;
    if (IPAddress) {
      ePosDev.connect(IPAddress, 8043, (data) => {
        if (data === 'SSL_CONNECT_OK') {
          setIsVisible(false);
          ePosDev.createDevice(
            'local_printer',
            ePosDev.DEVICE_TYPE_PRINTER,
            { crypto: true, buffer: false },
            (devobj, retcode) => {
              if (retcode === 'OK') {
                printer.current = devobj;
                setConnectionStatus(STATUS_CONNECTED);
              } else {
                setIsVisible(false);
                alert('issue connecting printer');
                throw retcode;
              }
            }
          );
        } else {
          setIsVisible(false);

          alert('issue connecting printer');
          throw data;
        }
      });
    }
  };

  const print = (transaction) => {
    try {
      printTickets({ printer, transaction: transaction });
    } catch (e) {
      console.error(e.toString());
    }
  };

  usePaymentSubscription(paymentIntent, (data) => {
    setPaymentIntent(null);
    if (data && data.posPurchaseConfirmation.status) {
      onPurchaseConfirmation(data.posPurchaseConfirmation.payment_intent);
      setPaymentIntent(null);
    } else {
      if (isVisible) errorMessage();
      setLoading(false);
      if (isVisible) setPaymentError(true);
    }
  });

  const posRequest = usePOSRequest();
  const cancelPos = useCancelPOS();

  const replaceUrl = () => {
    const url = new URL(window.location.href);

    // Remove specific query parameters
    url.searchParams.delete('payment_intent');
    url.searchParams.delete('payment_intent_client_secret');
    url.searchParams.delete('redirect_status');

    // Replace the URL
    history.replace(`${url.pathname}${url.search}`);
  };

  const onPurchaseConfirmation = async (paymentIntentManual) => {
    const seatsio = JSON.parse(sessionStorage.getItem('seatsio'));
    const holdToken = seatsio?.holdToken;

    const allTickets = Object.values(tickets).reduce((acc, value) => {
      const tickets = value.tickets?.map((ticket) => ({
        id: ticket.id,
        qty: ticket.count,
        ...(ticket.reserved_seat && ticket.count > 0
          ? {
              seats: ticket.seats?.map((seat) => ({
                seat_id: seat.id,
                ...(seat?.type ? { type: seat.type } : null),
              })),
            }
          : null),
      }));

      return [...acc, ...tickets];
    }, []);

    try {
      const result = await checkoutPos({
        event_id: +eventId,
        account_id: accountId,
        tickets: allTickets,
        hold_token: holdToken,
        payment_method: 'card',
        payment_intent: paymentIntentManual
          ? paymentIntentManual
          : paymentIntent,
      });

      if (result && result.data) {
        // Remove the tickets from storage
        localStorage.removeItem('tickets');

        // Print the tickets
        if (connectionStatus !== STATUS_CONNECTED && IPAddress) {
          await connectPrinter();
          print(result?.data?.checkoutPOS);
        } else if (connectionStatus === STATUS_CONNECTED) {
          print(result?.data?.checkoutPOS);
        }

        replaceUrl();

        setPurchaseId(result.data?.checkoutPOS);
        setLoading(false);
        setOpenSuccessModal(true);
        setIsVisible(false);
      } else {
        setPaymentError(true);
        errorMessage();
      }
    } catch (error) {
      if (manualCardPayment) history.goBack();
      setPaymentError(true);
      errorMessage();
    }
  };

  const makePosRequest = useCallback(async () => {
    if (isVisible && !manualCardPayment) {
      setLoading(true);
      setPaymentError(false);

      try {
        const result = await posRequest({
          account_id: accountId,
          amount: amount,
          reader_id: readerCookie,
        });

        if (result) {
          setPaymentIntent(result.data.posRequest);
        } else {
          setLoading(false);
          setPaymentError(true);
          errorMessage();
        }
      } catch (error) {
        setLoading(false);
        setPaymentError(true);
        if (!isCheckoutVisible && isVisible) errorMessage();
      }
    } else if (isVisible && manualCardPayment) {
      if (paymentIntentManual && redirectSuccess && accountIdPayment) {
        setLoading(true);

        onPurchaseConfirmation(paymentIntentManual);
      } else {
        setPaymentError(true);
        errorMessage();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, manualCardPayment, accountId, amount]);

  const cancelPosRequest = async () => {
    if (paymentIntent) {
      const result = await cancelPos({
        payment_intent: paymentIntent,
        reader_id: readerCookie,
      });

      return result.data.posCancel;
    }
  };

  useEffect(() => {
    if (isVisible) makePosRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const onClose = async () => {
    let result = !paymentIntent;
    if (paymentIntent) {
      result = await cancelPosRequest();
    }
    if (result) {
      setIsVisible(false);
    }
  };

  const handleManualCardPayment = async () => {
    let result = !paymentIntent;
    if (paymentIntent) {
      result = await cancelPosRequest();
    }
    if (result) {
      setIsVisible(false);
      setIsCheckoutVisible(true);
    }
  };

  const isTab = useMediaQuery({ query: '(max-width: 1190px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <Modal
      maxWidth={600}
      modalStyle={{
        height: isMobile ? '90vh' : '95vh',
        maxHeight: '95vh',
        padding: 0,
        right: isMobile ? 0 : 20,
        top: isTab ? '30px' : '2.5vh',
        width: isMobile ? '100vw' : '40vw', // Conditional width
        maxWidth: 550, // Disable maxWidth on smaller screens
        minWidth: isMobile ? 450 : null, // Full width on small screens
        margin: 'auto 0',
      }}
      isVisible={isVisible}
      hideModal={onClose}
      noHeader={true}
      overlayStyle={{
        zIndex: 10,
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            display: 'flex',
            padding: 10,
            justifyContent: 'space-between',
            marginBottom: 10,
            borderBottom: '1px solid #EAEAEA',
            marginTop: 20,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: 26,
              fontWeight: 'bold',
              paddingBottom: 20,
              marginLeft: 25,
              fontFamily: 'Barlow Condensed',
              color: '#000033',
            }}
          >
            Tap, Swipe or Insert Card
          </div>
          <div
            style={{
              transform: 'rotate(45deg)',
              display: 'flex',
              justifyContent: 'end',
              marginRight: 20,
            }}
          >
            <Icon
              icon="plus"
              color={theme.colors.primary}
              size={24}
              onClick={onClose}
              style={{ transform: 'rotate(45deg)', fontWeight: 'bold' }}
            />
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: paymentError ? 'center' : 'space-between',
            marginTop: paymentError ? '35%' : '0',
            height: '80vh',
          }}
        >
          {loading && !paymentError && <Loading type="spin" size={54} />}

          <div
            style={{
              paddingInline: 35,
            }}
          >
            {(!manualCardPayment || paymentError) && (
              <Button
                buttonStyle={{
                  borderRadius: 8,
                  minWidth: '70%',
                  marginInline: 'auto',
                  flex: 1,
                  padding: '13px 0',
                }}
                onClick={handleManualCardPayment}
              >
                <span
                  style={{
                    fontSize: '24px',
                    fontWeight: 700,
                    lineHeight: '34px',
                    fontFamily: 'Barlow Condensed',
                  }}
                >
                  Key in Card Number
                </span>
              </Button>
            )}

            <Spacer size={5} />
            {paymentError && !loading && (
              <Button
                buttonStyle={{
                  borderRadius: 8,
                  minWidth: '70%',
                  marginInline: 'auto',
                  flex: 1,
                  padding: '13px 0',
                }}
                onClick={makePosRequest}
              >
                <span
                  style={{
                    fontSize: '24px',
                    fontWeight: 700,
                    lineHeight: '34px',
                  }}
                >
                  Retry Transaction
                </span>
              </Button>
            )}

            <Spacer size={5} />

            <Button
              buttonStyle={{
                borderRadius: 8,
                minWidth: '70%',
                marginInline: 'auto',
                flex: 1,
                padding: '10px 0',
              }}
              textColor={'#fa4616'}
              onClick={onClose}
              outlined
            >
              <span
                style={{
                  fontSize: '24px',
                  fontWeight: 700,
                  lineHeight: '34px',
                }}
              >
                Cancel Transaction
              </span>
            </Button>
          </div>
        </div>
      </div>

      <CheckoutForm
        isVisible={isCheckoutVisible}
        setIsVisible={setIsCheckoutVisible}
        amount={amount}
        accountId={accountId}
      />

      <MobileDeliveryModal
        isVisible={openSuccessModal}
        setIsVisible={setOpenSuccessModal}
        showSuccessMessage={true}
      />
    </Modal>
  );
};
