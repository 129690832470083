import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Button } from 'components/Button';

const getDigits = (barcode) => {
  const digits = barcode.match(/\d+/g);
  return digits ? digits.join('') : '';
};

const getString = (barcode) => {
  const digits = barcode.match(/[a-zA-Z]+/g);
  return digits ? digits.join('') : '';
};

const getTickets = (transaction) => {
  return transaction.users
    .reduce((acc, user) => {
      return acc.concat(user.tickets);
    }, [])
    .filter((ticket) => 'bundle' !== ticket.type && !ticket.refunded);
};

export const printTickets = ({ printer, transaction }) => {
  const prn = printer.current;

  if (!prn) {
    alert('Not connected to printer');
    return;
  }

  const tickets = getTickets(transaction);

  tickets.forEach((ticket) => {
    try {
      prn.addTextAlign(prn.ALIGN_CENTER);

      prn.addTextSmooth(true);

      prn.addLogo(34, 34);

      prn.addFeedLine(2);

      prn.addTextSize(2, 2);
      prn.addTextStyle(false, false, true, prn.COLOR_1);
      prn.addTextFont(prn.FONT_C);

      prn.addText(ticket.name);
      prn.addTextFont(prn.FONT_A);
      prn.addTextStyle(false, false, false, prn.COLOR_1);

      if (ticket.section && ticket.row && ticket.seat) {
        prn.addFeedLine(2);
        prn.addTextSize(2, 2);
        prn.addText(ticket.section);
        prn.addFeedLine(2);
        prn.addText(`Row: ${ticket.row}   Seat: ${ticket.seat}`);
      }

      prn.addFeedLine(2);

      prn.addSymbol(
        ticket.barcode,
        prn.SYMBOL_QRCODE_MODEL_2,
        prn.LEVEL_Q,
        16,
        8,
        400,
        400
      );
      prn.addFeedLine(2);

      prn.addTextSize(3, 3);
      prn.addText(`${getDigits(ticket.barcode)} ${getString(ticket.barcode)}`);
      prn.addFeedLine(3);

      prn.addTextSize(2, 2);
      prn.addText(ticket.date);

      prn.addFeedLine(2);
      prn.addText(transaction.event.name);
      prn.addFeedLine(2);
      prn.addTextSize(1, 1);
      prn.addText(transaction.track.name);
      prn.addFeedLine(2);
      prn.addTextSize(2, 2);
      if (transaction.card) {
        prn.addText(`Order # ${transaction.id} | CC`);
      } else {
        prn.addText(`Order # ${transaction.id} | CASH`);
      }

      prn.addFeedLine(2);
      prn.addTextSize(1, 1);
      prn.addText('RAIN CHECK - KEEP THIS TICKET');
      prn.addFeedLine(2);

      prn.addText('Get the Ticket Hoss App and Skip the Line!');
      prn.addFeedLine(5);
      prn.addCut(prn.CUT_FEED);

      prn.send();
    } catch (error) {
      alert(error);
    }
  });
};

const STATUS_CONNECTED = 'Connected';

const PrintTickets = ({ connectionStatus, printer, transaction }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const onPrintTicket = () => {
    printTickets({ printer, transaction });
  };

  return (
    <Button
      buttonStyle={
        isMobile ? { width: '100px', margin: '0 10px' } : { margin: '0 10px' }
      }
      onClick={onPrintTicket}
      disabled={connectionStatus !== STATUS_CONNECTED}
    >
      PRINT TICKETS
    </Button>
  );
};

export default PrintTickets;
