import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Button } from 'components/Button';

// const getTickets = (transaction) => {
//   return transaction.users
//     .reduce((acc, user) => {
//       return acc.concat(user.tickets);
//     }, [])
//     .filter((ticket) => 'bundle' !== ticket.type && !ticket.refunded);
// };

const PrintReceipt = ({ connectionStatus, printer }) => {
  const STATUS_CONNECTED = 'Connected';
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const printReceipt = () => {
    const prn = printer.current;

    if (!prn) {
      alert('Not connected to printer');
      return;
    }

    // const tickets = getTickets(transaction);

    try {
      prn.addTextAlign(prn.ALIGN_CENTER);
      prn.addTextSmooth(true);

      prn.addLogo(32, 32);

      prn.addFeedLine(2);

      prn.addTextSize(2, 2);
      prn.addText('Gandrud Auto Group 350');
      prn.addFeedLine(1);
      prn.addText('@');
      prn.addFeedLine(1);
      prn.addTextSize(1, 1);
      prn.addText('Talladega Short Track and Raceway Park');

      prn.addFeedLine(2);
      prn.addTextSize(2, 2);
      prn.addText('SUN DEC 1 - 2024');
      prn.addFeedLine(5);

      prn.addTextAlign(prn.ALIGN_LEFT);
      prn.addText('TICKETS');
      prn.addPageLine(0, 100, 300, 100, prn.LINE_MEDIUM);
      prn.addFeedLine(2);

      prn.addTextSize(1, 1);
      prn.addTextAlign(prn.ALIGN_LEFT);
      prn.addText('(2) Friday Adult (13+) General Admission   $35.00');
      prn.addFeedLine(1);
      prn.addText('(2) Saturday Adult (13+) General Admission   $35.00');
      prn.addFeedLine(1);
      prn.addText(
        '(2) Saturday Child (12 and under) General Admission   $35.00'
      );
      prn.addFeedLine(2);

      prn.addFeedLine(5);
      prn.addCut(prn.CUT_FEED);

      prn.send();
    } catch (error) {
      alert(error);
    }
  };
  return (
    <Button
      buttonStyle={
        isMobile ? { width: '100px', margin: '0 10px' } : { margin: '0 10px' }
      }
      onClick={() => {
        printReceipt();
      }}
      disabled={connectionStatus !== STATUS_CONNECTED}
    >
      PRINT RECEIPT
    </Button>
  );
};

export default PrintReceipt;
