import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useGetEvent } from 'components/Events/gql';
import Loading from 'components/Loading';
import Spacer from 'components/Spacer';
import { useCart } from 'shared/CartContext';
import { CartList } from './Components/CartList';
import { EmptyCart } from './Components/EmptyCart';
import { PaymentOption } from './Components/PaymentOption';
import { ReaderIpModal } from './Components/ReaderIpModal';
import { TicketHeader } from './Components/TicketHeader';
import { TicketList } from './Components/TicketList';

const MainContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: rgb(0, 0, 31);
  color: white;
  font-size: large;
  height: 95vh;
  font-family: 'Barlow Condensed';

  @media (max-width: 984px) {
    height: auto;
  }
`;

const LeftContainer = styled.div`
  flex: 65%;
  padding: 20px;
  box-sizing: border-box;
  height: 100%;

  @media (max-width: 768px) {
    max-width: -webkit-fill-available;
  }
`;

const RightContainer = styled.div`
  flex: 35%;
  padding: 20px;
  box-sizing: border-box;
  height: 100%;
  padding-left: 0;
  @media (max-width: 1098px) {
    background: #00001f;
  }

  @media (max-width: 768px) {
    padding-left: 20px;
  }
`;

const CartPage = () => {
  const params = useParams();
  const EventId = parseInt(params.id);
  const { data, loading } = useGetEvent(EventId);

  const [event, setEvent] = useState(null);
  const { ticketCount, setSeatId, setAccountId, accountId } = useCart();

  const [isCheckoutVisible, setIsCheckoutVisible] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const payment_intent = urlParams.get('payment_intent');
  const payment_id = urlParams.get('payment_intent_client_secret');

  const [isReaderIpVisible, setIsReaderIpVisible] = useState(true);

  useEffect(() => {
    // Clear storage initially
    localStorage.removeItem('tickets');
    localStorage.removeItem('selectedTicket');

    // Remove any old timer keys
    Object.keys(localStorage).forEach((key) => {
      if (key.includes('timer')) localStorage.removeItem(key);
    });
  }, []);

  useEffect(() => {
    if (data && data.getEvent) {
      setEvent(data.getEvent);
    }
  }, [data]);

  useEffect(() => {
    if (event && event.seats_id) {
      setSeatId(event.seats_id);
    }

    if (event && event.account_id) {
      setAccountId(event.account_id);
    }
  }, [data, event, setSeatId, setAccountId]);

  useEffect(() => {
    // Check if payment_intent and payment_id are in the url and open payment option modal if they are
    if (payment_intent && payment_id && accountId) {
      setIsCheckoutVisible(true);
    }
  }, [payment_intent, payment_id, accountId]);

  if (loading && !data) {
    return <Loading />;
  }

  return (
    <MainContainer>
      <LeftContainer>
        <TicketHeader event={event} />
        <Spacer size={20} />
        <TicketList event={event} />
      </LeftContainer>
      <RightContainer>
        {ticketCount === 0 ? <EmptyCart /> : <CartList />}
      </RightContainer>

      <PaymentOption
        isVisible={isCheckoutVisible}
        setIsVisible={setIsCheckoutVisible}
        manualCardPayment={!!payment_intent}
        accountIdPayment={accountId}
      />

      <ReaderIpModal
        isVisible={isReaderIpVisible}
        setIsVisible={setIsReaderIpVisible}
      />
    </MainContainer>
  );
};

export default CartPage;
