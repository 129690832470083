import { gql } from 'graphql-tag';
import { usePersistedQuery } from './usePersistedQuery';

export const ME = gql`
  query Me {
    me {
      id
      track {
        id
        name
        logo
        cityAndState
      }
      series {
        id
        name
        logo
        website
        type {
          key
          value
        }
      }
      ownership {
        tracks {
          id
          name
        }
        series {
          id
          name
        }
      }
      name
      first_name
      last_name
      message_center
      registrations
      role
      messageCount
      racerCount
      staffCount
      guestCount
      accounts {
        id
        business_dba
        janam_scanners
        card {
          brand
          last4
        }
      }
      capabilities {
        account_id
        events
        guests
        add_guests
        messages
        add_promos
        promos
        credits
        issue_credits
        transactions
        issue_refunds
        registrations
        customers
        memberships
        staff
        add_staff
        stripe
        scan_tickets
        check_tickets
        create_messages
        edit_registrations
        check_in_guests
        edit_memberships
        edit_billing
        pos
      }
      promoters {
        cellphone
        name
      }
    }
  }
`;

export function useMe() {
  const result = usePersistedQuery(ME, `ME`);

  return result;
}
