import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [tickets, setTickets] = useState([]);
  const [types, setTypes] = useState(null);
  const [selected, setSelected] = useState('0');

  const [ticketCount, setTicketCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [feesTotal, setFeesTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [amount, setAmount] = useState(0);

  // Load data from localStorage on component mount
  useEffect(() => {
    const savedTickets = localStorage.getItem('tickets');
    if (savedTickets) {
      setTickets(JSON.parse(savedTickets));
    }
    // Load other state variables here...
  }, []);

  // Save data to localStorage whenever state changes
  useEffect(() => {
    localStorage.setItem('tickets', JSON.stringify(tickets));
  }, [tickets]);

  const [selectedSeatObject, setSelectedSeatObject] = useState({});

  const [seatId, setSeatId] = useState(null);
  const [accountId, setAccountId] = useState(null);
  const [purchaseId, setPurchaseId] = useState(null);

  const [seatsModalIsVisible, setSeatsModalIsVisible] = useState(false);

  const [connectionStatus, setConnectionStatus] = useState('');

  // Reference for the chart
  const chartRef = useRef(null);

  // Reference for printer
  const printer = useRef();

  // Add a new ticket
  const addTicket = (ticket) => {
    setTicketCount((count) => count + 1);

    setTickets((tickets) => {
      const newTickets = tickets[selected].tickets?.reduce((acc, tick) => {
        if (ticket.id === tick.id) {
          // If the ticket is with reserved seat, add the seat to the seats array
          if (tick.reserved_seat) {
            return [
              ...acc,
              {
                ...tick,
                count: tick.count + 1,
                seats:
                  tick.seats && tick.seats.length > 0
                    ? [...tick.seats, ticket.seat]
                    : [ticket.seat],
              },
            ];
          }
          return [...acc, { ...tick, count: tick.count + 1 }];
        } else return [...acc, { ...tick }];
      }, []);

      return {
        ...tickets,
        [selected]: { ...tickets[selected], tickets: newTickets },
      };
    });
  };

  const removeTicket = (ticket) => {
    setTicketCount((count) => (count ? count - 1 : 0));

    setTickets((tickets) => {
      const newTickets = tickets[selected]?.tickets?.reduce((acc, tick) => {
        // If the ticket is with reserved seat, remove the seat from the seats array
        if (ticket.id === tick.id && tick.reserved_seat) {
          const newSeats = tick?.seats?.filter(
            (seat) => seat.id !== ticket.seat.id
          );

          return [
            ...acc,
            {
              ...tick,
              count: tick.count ? tick.count - 1 : 0,
              seats: newSeats,
            },
          ];
        } else if (ticket.id === tick.id)
          return [...acc, { ...tick, count: tick.count ? tick.count - 1 : 0 }];
        else return [...acc, { ...tick }];
      }, []);

      return {
        ...tickets,
        [selected]: { ...tickets[selected], tickets: newTickets },
      };
    });
  };

  // Add the ticket on seat selection
  const addTicketSeat = (seat) => {
    // Get selected seat from storage
    const selectedTicket = localStorage.getItem('selectedTicket');

    const ticket = tickets[selected]?.tickets.find(
      (tick) => tick.id === +selectedTicket
    );

    addTicket({
      ...ticket,
      seat: seat,
      price: seat?.price ?? ticket.track_price,
    });

    setSelectedSeatObject((seats) => {
      //Check if the category already exists in the object
      if (seats[seat?.labels?.section]) {
        //If it exists, add the seat to the array
        seats[seat?.labels.section].push({
          ...seat,
          ticket: { ...ticket, count: 1 },
        });
      } else {
        //If it doesn't exist, create a new array and add the seat to it
        seats[seat?.labels.section] = [
          { ...seat, ticket: { ...ticket, count: 1 } },
        ];
      }

      return seats;
    });
  };

  // Remove the ticket on seat deselection
  const removeTicketSeat = (seat) => {
    // Get selected seat from storage
    const selectedTicket = localStorage.getItem('selectedTicket');

    const ticket = tickets[selected]?.tickets.find(
      (tick) => tick.id === +selectedTicket
    );

    removeTicket({ ...ticket, seat: seat });

    setSelectedSeatObject((seats) => {
      //Check if the category already exists in the object
      if (seats[seat?.labels.section]) {
        //If it exists, remove the seat from the array
        seats[seat?.labels.section] = seats[seat?.labels.section].filter(
          (prevSeat) => prevSeat.id !== seat.id
        );

        //check if the category is empty, if it is, remove it from the object
        if (seats[seat?.labels.section].length === 0) {
          delete seats[seat?.labels.section];
        }
      }

      return seats;
    });
  };

  const removeAllTicket = (ticket) => {
    const newTickets = Object.keys(tickets).reduce((acc, key) => {
      const newTickets = tickets[key].tickets.map((tick) => {
        if (ticket.id === tick.id && !tick.reserved_seat) {
          setTicketCount((count) => count - tick.count);

          return { ...tick, count: 0 };
        } else return { ...tick };
      });

      return [...acc, { ...tickets[key], tickets: newTickets }];
    }, []);

    setTickets(newTickets);
  };

  const clearTicket = async () => {
    const newTickets = Object.keys(tickets).reduce((acc, key) => {
      const newTickets = tickets[key].tickets.map((tick) => {
        return { ...tick, count: 0, seats: [] };
      });

      return [...acc, { ...tickets[key], tickets: newTickets }];
    }, []);

    await resetSeats();

    setTickets(newTickets);
    setTicketCount(0);
  };

  const resetSeats = async () => {
    if (chartRef.current) {
      if (chartRef.current?.chart) chartRef.current.chart.startNewSession();

      // Add a delay before closing the modal
      setTimeout(() => {
        setSelectedSeatObject({});
      }, 500);
    }
  };

  return (
    <CartContext.Provider
      value={{
        tickets,
        addTicket,
        setTickets,
        removeTicket,
        ticketCount,
        setTicketCount,
        total,
        setTotal,
        amount,
        setAmount,
        feesTotal,
        setFeesTotal,
        clearTicket,
        removeAllTicket,
        types,
        setTypes,
        discount,
        setDiscount,
        subtotal,
        setSubtotal,
        selectedSeatObject,
        setSelectedSeatObject,
        seatId,
        setSeatId,
        addTicketSeat,
        removeTicketSeat,
        chartRef,
        resetSeats,
        seatsModalIsVisible,
        setSeatsModalIsVisible,
        accountId,
        setAccountId,
        purchaseId,
        setPurchaseId,
        selected,
        setSelected,
        connectionStatus,
        setConnectionStatus,
        printer,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error('UseCart must be used within a CartProvider');
  }
  return context;
};
